<template>
  <div>
    <!-- 底部固定的操作 -->
    <div class="bottom">
      <div class="shop_cart_left" @click="handleDisplayDetail">
        <div class="shop_cart_image">
          <img src="./shopCart.png" />
        </div>
        <!-- 数量徽标 -->
        <span v-if="selectedWareList.length > 0" class="badge"
          >{{
            selectedWareList.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.goodsNum,
              0,
            )
          }}
        </span>
      </div>

      <!-- 右侧确定按钮 -->
      <div class="shop_cart_right">
        <button
          :class="{
            primary: true,
            submit_btn: true,
            // disabled:
            //   selectedWareList.reduce(
            //     (accumulator, currentValue) =>
            //       accumulator + currentValue.number,
            //     0,
            //   ) === 0,
          }"
          @click="handleSelectConfirm"
        >
          确定
        </button>
      </div>
    </div>

    <!-- 已选商品 -->
    <div
      v-if="showCartDetail && selectedWareList.length > 0"
      :class="{ action_sheet_warpper: true }"
      id="action_sheet_warpper"
    >
      <div class="top">
        <span class="tip">已选商品</span>
        <span class="clear" @click="handleClear">清空</span>
      </div>

      <div>
        <!-- <van-list
          v-model="false"
          :finished="true"
          finished-text="1"
          @load="onLoad"
        >
          <p>1</p>
        </van-list> -->
        <DrugAddReduce
          v-for="(item, index) in selectedWareList"
          :key="item.goodsId"
          :item="item"
          mode="edit"
          @onHandleSelect="handleSelect"
          @onHandleInvertSelect="handleInvertSelect"
        />
      </div>

      <!-- <van-list
        v-model="false"
        :finished="true"
        finished-text=""
        @load="onLoad"
      >
        
      </van-list> -->
    </div>

    <!-- 蒙板 -->
    <div
      v-if="showCartDetail && selectedWareList.length > 0"
      class="mask"
      @click="handleClickMask"
      @mousemove.stop="() => {}"
    ></div>
    <!-- TODO 穿透问题待解决 -->
  </div>
</template>

<script>
import DrugAddReduce from '@/components/drugAddReduce';


import { Dialog } from 'vant';
export default {
  name: 'cart',
  components: {
    DrugAddReduce,
  },
  data () {
    return {
      showCartDetail: false,
    }
  },
  props: {
    // 选中的药品列表
    selectedWareList: {
      type: Array,
      default () {
        return []
      },
      required: true
    }
  },
  methods: {
    handleClickMask () {
      this.showCartDetail = false
    },
    handleClear () {
      Dialog.confirm({
        title: '提示',
        message: '确认清空购药箱吗？',
      })
        .then(() => {
          // on confirm
          this.$emit('clearCart')
          this.showCartDetail = false
        })
        .catch(() => {
          // on cancel
        });
    },
    handleDisplayDetail () {
      this.showCartDetail = true
    },
    onLoad () { },
    // 点击确定
    handleSelectConfirm () {
      // if (this.selectedWareList.length === 0) {
      //   // 无数量，直接返回
      //   return
      // }
      // props.onHandlConfirm()
      // 有数量，抛出事件
      this.$emit('onHandlConfirm')
    },

    handleSelect (item, index) {
      this.$emit('onHandleSelect', item, index)
    },
    handleInvertSelect (item, index) {
      this.$emit('onHandleInvertSelect', item, index)
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  position: fixed;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 -9.5px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 60px;
  line-height: 60px;
  box-shadow: 0px 0px 8px 0px rgba(60, 106, 240, 0.1);
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: space-between;
  z-index: 1900;
  &::after {
    content: '';
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: env(safe-area-inset-bottom);
    background-color: #fff;
    z-index: -1;
    background-clip: padding-box; /* 保证背景颜色不会超出元素边框 */
  }

  .shop_cart_left {
    display: flex;
    position: relative;
  }
  .shop_cart_image {
    width: 44px;
    height: 44px;
    img {
      width: 100%;
      height: 100%;
    }
    &.disabled {
      opacity: 0.34;
    }
  }

  .badge {
    position: absolute;
    left: 30px;
    top: 0;
    height: 14px;
    min-width: 14px;
    padding: 0 3px;
    border-radius: 7px;
    border: 1px solid #fff;
    background: #ff4a31;
    box-shadow: 0px 0px 5px 0px rgba(68, 134, 251, 0.12);
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    box-sizing: border-box;
  }

  .submit_btn {
    width: 136px;
    height: 44px;
    line-height: 44px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 14px;
    border-radius: 40px;
    border: none;
    vertical-align: top;
    &.disabled {
      opacity: 0.34;
    }
    &:active {
      color: #fff;
      background: #f36218;
    }
    &.primary {
      background: #f36218;
      color: #fff;
    }
  }
}

.action_sheet_warpper {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  max-height: 70%;
  border-radius: 6px 6px 0px 0px;
  background: #fff;
  overflow: scroll;
  z-index: 1800;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: alternate;
  &.btn_adapt {
    bottom: 174px;
  }
  .top {
    position: sticky;
    top: 0;
    width: 100%;
    height: 43px;
    z-index: 1000;
    background: #fff;
    border-bottom: 1px solid #aaa;
    .tip {
      position: absolute;
      left: 20px;
      top: 11px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
    .clear {
      position: absolute;
      right: 20px;
      top: 11px;
      font-size: 14px;
      font-weight: 400;
      color: #90949e;
      line-height: 20px;
      &::before {
        position: absolute;
        top: -1px;
        right: 30px;
        display: inline-block;
        content: '';
        width: 22px;
        height: 22px;
        background-image: url('./delete@2x.png');
        background-size: 22px 22px;
      }
    }
  }
  .drug_list {
    height: calc(100% - 86px);
  }
}

.mask {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

/deep/ .van-overlay {
  z-index: 10000 !important;
}
</style>
