<template>
  <div class="container">
    <div class="header">
      <div class="content">
        <img class="magnifier" src="./btn_serch_grey@2x.png" alt="" />
        <input
          type="text"
          class="input"
          v-model="inputValue"
          ref="inputRef"
          placeholder="请输入搜索关键词"
        />
        <!-- <van-search
          v-model="inputValue"
          placeholder="请输入搜索关键词"
          shape="round"
        /> -->
      </div>
      <span class="search" @click="handleSearch">搜索</span>
    </div>
    <div class="flex_wrapper" v-if="mode === 1">
      <div class="search_hot">
        <div class="title">热门搜索</div>
        <div class="content">
          <div
            v-for="item in tags"
            :key="item.id"
            @click="handleSelected(item.title)"
            class="tag"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <div class="list" v-if="mode === 2">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        :immediate-check="false"
        finished-text="没有更多了"
        offset="60"
      >
        <DrugAddReduce
          v-for="(item, index) in medicineData"
          :item="item"
          :key="index"
          :orderIndex="index"
          :mode="isApply === 'true' ? 'edit' : 'plain'"
          @onHandleSelect="handleSelect"
          @onHandleInvertSelect="handleInvertSelect"
          @onClickItem="handleClickItem"
        />
        <div v-if="medicineData.length === 0 && !loading" class="empty_box">
          <img src="~@img/empty.png" alt="" class="empty_img" />
          <p class="empty_tip"></p>
        </div>
      </van-list>
    </div>

    <Cart
      v-if="isApply === 'true'"
      :selectedWareList="selectedWareList"
      @onHandleSelect="handleSelect"
      @onHandleInvertSelect="handleInvertSelect"
      @onHandlConfirm="handleConfirm"
      @clearCart="handleClearCart"
    />
  </div>
</template>

<script>

import Cart from '@/components/cart';
import DrugAddReduce from '@/components/drugAddReduce';
import { getQueryStringArgs } from '@/utils';
import api from '@/api'

export default {
  name: 'searchMedicine',
  components: {
    Cart,
    DrugAddReduce
  },
  data () {
    return {
      isApply: false,
      mode: 1,
      medicineData: [

      ],
      selectedWareList: [
        // {
        //   name: '九期一',
        //   usualName: '甘露特纳胶囊',
        //   specs: '1mg/片',
        //   unitName: '盒',
        //   provider: '上海绿谷制药有限公司',
        //   number: 10,
        //   storeName: '北京四季堂大药房1'
        // },
        // {
        //   name: '可瑞达',
        //   usualName: '甘露特纳胶囊',
        //   specs: '1mg/片',
        //   unitName: '盒',
        //   provider: '上海绿谷制药有限公司',
        //   number: 1,
        //   storeName: '北京四季堂大药房2'
        // },
        // {
        //   name: '艾瑞卡',
        //   usualName: '甘露特纳胶囊1',
        //   specs: '1mg/片',
        //   unitName: '盒',
        //   provider: '上海绿谷制药有限公司',
        //   number: 2,
        //   storeName: '北京四季堂大药房3'
        // },
      ],
      loading: false,
      finished: true,
      inputValue: '',
      tags: [
        { id: 1, title: '注射用胸腺法新' }, { id: 2, title: '帕博利珠单抗' }, { id: 3, title: '依洛尤单抗' }, { id: 4, title: '信迪利单抗' },
        { id: 5, title: '注射用卡瑞利珠单抗' }, { id: 6, title: '特瑞普利单抗' }, { id: 7, title: '尼妥珠单抗' }, { id: 8, title: '地舒单抗' },
        { id: 9, title: '卡度尼利单抗' }, { id: 10, title: '纳武利尤单抗' }
      ],
      pageNumber: 1,
    }
  },

  created () {
    const queryObj = getQueryStringArgs()
    console.log('queryObj.isApply', queryObj.isApply, typeof queryObj.isApply);
    this.queryObj = queryObj
    this.isApply = queryObj.isApply

    this.selectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")
  },

  watch: {
    inputValue (newV) {
      if (!newV) {
        this.mode = 1
      }
    }
  },
  methods: {
    handleClearCart () {
      this.selectedWareList = []
      sessionStorage.removeItem('selectedWareList')
      const copyMedicineData = this.medicineData.map(item => {
        // 当前商品在购物车中
        return {
          ...item,
          goodsNum: 0,
          isSelected: false,
        }
      })
      this.medicineData = copyMedicineData
    },
    handleClickItem (item) {
      console.log('handleClickItem', console.log('item', item));
      sessionStorage.setItem('homeMedicine', JSON.stringify(item))
      this.$router.go(-2)
    },
    handleConfirm () {
      // 清空所选
      // sessionStorage.removeItem('selectedWareList')
      // 返回页面
      this.$router.go(-2)
    },
    handleInvertSelect (item, index) {
      const findIndex = this.medicineData.findIndex(ware => ware.goodsId === item.goodsId)
      const copyWareList = JSON.parse(JSON.stringify(this.medicineData))

      if (findIndex > -1) {
        // 设置选中
        copyWareList[findIndex].goodsNum = copyWareList[findIndex].goodsNum - 1
        copyWareList[findIndex].isSelected = copyWareList[findIndex].goodsNum > 0
        this.medicineData = copyWareList
      }

      const copySelectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")
      const findIndex2 = copySelectedWareList.findIndex(current => current.goodsId === item.goodsId)
      if (findIndex2 > -1) {
        copySelectedWareList[findIndex2].goodsNum = copySelectedWareList[findIndex2].goodsNum - 1
        if (copySelectedWareList[findIndex2].goodsNum === 0) {
          copySelectedWareList[findIndex2].isSelected = false
          copySelectedWareList.splice(findIndex2, 1)
        }
      }
      this.selectedWareList = copySelectedWareList
      // 同步到全局数据中
      sessionStorage.setItem('selectedWareList', JSON.stringify(copySelectedWareList))
    },
    handleSelect (item, index) {
      const findIndex = this.medicineData.findIndex(ware => ware.goodsId === item.goodsId)
      if (findIndex > -1) {
        const copyWareList = JSON.parse(JSON.stringify(this.medicineData))
        copyWareList[findIndex].isSelected = true
        copyWareList[findIndex].goodsNum = copyWareList[findIndex].hasOwnProperty('goodsNum') ? Number(copyWareList[findIndex].goodsNum) + 1 : 1
        this.medicineData = copyWareList
      }

      const copyItem = JSON.parse(JSON.stringify(item))

      if (copyItem.hasOwnProperty('goodsNum')) {
        copyItem.goodsNum = Number(copyItem.goodsNum) + 1
      } else {
        copyItem.goodsNum = 1
      }

      const copySelectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")

      // 区分 是否已经有了
      const index1 = copySelectedWareList.findIndex(ite => ite.goodsId === copyItem.goodsId)
      if (index1 > -1) {
        // 已经有了
        copySelectedWareList[index1].goodsNum = copyItem.goodsNum
      } else {
        // 没有
        copySelectedWareList.push(copyItem)
      }
      this.selectedWareList = copySelectedWareList
      // 同步到全局数据中
      sessionStorage.setItem('selectedWareList', JSON.stringify(copySelectedWareList))
    },

    async searchGoods (keywords, isClickSearch) {
      let res = {}
      if (this.isApply === 'true') {
        // 是预约
        res = await api.getApplyGoods({
          "cityName": decodeURIComponent(this.queryObj.cityName),
          "infusionCenterCode": this.queryObj.infusionCenterCode,
          keywords,
          "pageNumber": this.pageNumber,
          "pageSize": 10
        })
      } else {
        // 是首页
        res = await api.getDrugs({
          keywords,
          "pageNumber": this.pageNumber,
          "pageSize": 10,
          "productIds": []
        })
      }


      if (+res.code === 0) {
        if (res.data && res.data.list) {
          if (isClickSearch) {
            // 点击的搜索
            this.medicineData = res.data.list || []
          } else {
            // 加载更多
            this.medicineData = this.medicineData.concat((res.data && res.data.list) || [])
          }

          // const listData = res.data.list
          this.medicineData.forEach(item => {
            const findRes = this.selectedWareList.find(item1 => {
              return item1.goodsId === item.goodsId
            })
            if (findRes) {
              item.goodsNum = findRes.goodsNum
              item.isSelected = true
            }
          })
          // this.medicineData = listData
        }

        // 告诉van-list
        this.loading = false
        const { totalPage, pageNumber, totalNumber } = res.data
        if (Number(pageNumber) < Number(totalPage)) {
          this.pageNumber = this.pageNumber + 1
          this.finished = false
        }

        // 最后一页逻辑
        if (totalPage === pageNumber) {
          this.finished = true
        }

        if (Number(totalNumber) === 0) {
          this.finished = true
        }
      }
    },

    async handleClickSearch (drugName) {
      this.mode = 2
      this.searchGoods(drugName, true)
    },

    handleSearch () {
      if (!this.inputValue) {
        return
      }

      this.mode = 2
      this.searchGoods(this.inputValue && this.inputValue.trim(), true)
    },

    // 选择热门
    handleSelected (title) {
      this.inputValue = title
      this.handleClickSearch(title)
    },
    onLoad () {
      this.loading = true
      this.searchGoods(this.inputValue && this.inputValue.trim())
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 44px;
    padding: 8px 18px 8px 14px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(60, 107, 240, 0.1);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    .content {
      position: relative;
      width: 301px;
      height: 28px;
      background: #f5f7fa;
      border-radius: 100px;
      .magnifier {
        position: absolute;
        top: 4px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      .input {
        position: absolute;
        top: 4px;
        left: 40px;
        width: 250px;
        height: 20px;
        border: none;

        background: #f5f7fa;

        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400px;
        line-height: 20px;
      }
    }
    .search {
      position: absolute;
      right: 18px;
      top: 12px;

      // width: 28px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3c6bf0;
      line-height: 20px;
    }
  }

  .flex_wrapper {
    position: fixed;
    top: 44px;
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 104px);
    background: #fff;
    .drug_list {
      padding-top: 10px;
      // margin-top: 20px;
      height: calc(100% - 104px);
      background: #fff;
    }
    .search_hot {
      padding-left: 18px;
      // height: 100%;
      background: #fff;
      // overflow: hidden;
      .title {
        margin: 24px 0 18px 0;
        font: 400 14px PingFangSC-Regular, PingFang SC;
        color: #90949e;
        line-height: 20px;
      }
      .content {
        .tag {
          float: left;
          margin: 0 10px 10px 0;
          width: 132px;
          height: 28px;
          line-height: 28px;
          // font: 400 28px/56px PingFangSC-Regular, PingFang SC;
          text-align: center;
          color: #90949e;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 100px;
          // line-height: 56px;
        }
        .active {
          background: rgba(247, 109, 50, 0.05);
          border: 1px solid #f76d32;
          color: #f76d32;
        }
      }
    }
  }

  .list {
    // position: fixed;
    // top: 44px;
    padding-top: 44px;
    padding-bottom: 60px;
    width: 100%;
    .medicine_item {
      padding: 15px;
      background: #fff;
      position: relative;
      // border-bottom: 1px solid #ccc;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 10px; /* 这里的值相当于左padding */
        right: 10px; /* 这里的值相当于右padding */
        border-bottom: 1px solid #e5e5e5; /* 设置底部边框 */
      }

      .name {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px;
      }

      .specs {
        font-size: 12px;
        color: #90949e;
        margin-bottom: 10px;
      }

      .provider {
        font-size: 12px;
        color: #90949e;
      }
    }
  }
}

.empty_box {
  height: calc(100% - 88px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
}
</style>