<template>
  <div class="container">
    <div class="card">
      <div class="content" @click="handleClickItem">
        <div class="content_left">
          <div class="title_wrapper mb10 name_wrapper">
            <span class="title_main">{{
              mode === 'plain' ? item.productName : item.name
            }}</span>
            （<span class="title_sub">{{ item.usualName }}</span
            >）
          </div>
          <div class="mb8">
            <span class="tip">{{ '规格：' + (item.specs || '') }}</span>
            <span class="tip tip_add">{{
              '单位：' + (item.unitName || item.unit || '')
            }}</span>
          </div>
          <div
            :class="[mode === 'edit' ? 'provider' : '', 'tip_wrapper', 'mb8']"
          >
            <span class="tip">
              {{ '生产企业：' + (item.providerName || '') }}
            </span>
          </div>
          <div class="drug" v-if="mode === 'edit'">
            <!-- <span class="tip">
              {{ '药房：' + (item.fullName || '') }}
            </span> -->
            <span class="pharmacy">药房：{{ item.fullName }}</span>
            <span @click="handleMakePhone" class="tel">{{
              item.pharmacyTel
            }}</span>
          </div>
          <div class="tip_wrapper" v-if="mode === 'edit'">
            <span class="tip">
              {{ '地址：' + (item.pharmacyAddress || '') }}
            </span>
          </div>
        </div>

        <!-- + -->
        <img
          v-if="mode === 'edit' && (!item.goodsNum || item.goodsNum < 10)"
          class="icon_add"
          src="./icon-add.svg"
          @click.stop="handleSelect"
        />

        <!-- +灰色 -->
        <img
          v-if="mode === 'edit' && item.goodsNum === 10"
          class="icon_add"
          src="./icon-add_gray.svg"
          @click.stop="handleAddGray"
        />

        <!-- 数量 -->
        <span
          v-if="mode === 'edit' && item.goodsNum && item.goodsNum > 0"
          class="number"
          >{{ item.goodsNum }}</span
        >

        <!-- - -->
        <img
          v-if="mode === 'edit' && item.goodsNum && item.goodsNum > 0"
          class="icon_has_added"
          src="./icon-hasAdded.svg"
          @click="handleInvertSelect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'drugAddReduce',
  data () {
    return {

    }
  },
  props: {
    item: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    // itemKey: {
    //   type: Number,
    //   required: true,
    //   default () {
    //     return ''
    //   }
    // },
    // orderIndex: {
    //   type: Number,
    //   required: true,
    //   default () {
    //     return 0
    //   }
    // },
    mode: {
      type: String,
      required: true,
      default () {
        return 'plain' // 'edit'
      }
    },
  },
  methods: {
    handleMakePhone () {
      window.location.href = `tel:${this.item.pharmacyTel}`
    },
    handleClickItem () {
      if (this.mode === 'plain') {
        this.$emit('onClickItem', this.item)
      }
    },
    // 选中一个药品
    handleSelect () {
      const selectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")

      // 是否在已选
      const currentIsInSelected = selectedWareList.find(currentItem => {
        return currentItem.goodsId === this.item.goodsId
      })
      if ((selectedWareList && selectedWareList.length === 5) && !currentIsInSelected) {
        return Toast.fail('最多选择5个药品～')
      } else {
        this.$emit('onHandleSelect', this.item)
      }

    },

    handleAddGray () {
      return Toast.fail('数量最多选择10个')
    },

    handleInvertSelect () {
      this.$emit('onHandleInvertSelect', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "@/styles/scale_1px.scss";
.mr8 {
  margin-right: 8px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb28 {
  margin-bottom: 28px;
}
.mt28 {
  margin-top: 28px;
}

.card {
  position: relative;
  padding-left: 18px;
  // background: rgba(255, 255, 255, 0.1);
  background: #fff;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 15px; /* 这里的值相当于左padding */
    right: 15px; /* 这里的值相当于右padding */
    border-bottom: 1px solid #e5e5e5; /* 设置底部边框 */
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 0;
    // @include scale_1px_after;

    .title_wrapper {
      font-size: 16px;
      line-height: 22px;
      .title_sub {
        color: #333;
      }
      &.name_wrapper {
        width: 224px;
      }
    }

    .tip_wrapper {
      width: 224px;
    }

    .provider {
      position: relative;
      padding-bottom: 10px;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 1px; /* 这里的值相当于左padding */
        right: -115px; /* 这里的值相当于右padding */
        border-bottom: 1px dashed #e9e9e9; /* 设置底部边框 */
      }
    }

    .drug {
      margin-bottom: 4px;
      // height: 18px;
      line-height: 18px;
      font-size: 12px;
      color: #666;
      width: 339px;
      display: flex;
      .pharmacy {
        flex: 1;
        &::before {
          top: 4px;
          content: '';
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url('./pharmacy.svg');
          background-size: 14px 14px;
          vertical-align: text-top;
          margin-right: 2px;
        }
      }
      .tel {
        width: 120px;
        text-align: right;
        &::before {
          top: 4px;
          content: '';
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url('./telephone@2x.png');
          background-size: 14px 14px;
          vertical-align: text-top;
          margin-right: 2px;
        }
      }
    }

    .tip {
      // font: 400 24px/34px PingFangSC-Regular, PingFang SC;
      color: #90949e;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .tip_add {
      margin-left: 20px;
    }
  }
  .icon_arrow {
    width: 12px;
    height: 12px;
  }

  .yuan {
    position: absolute;
    right: 16px;
    top: 20px;
    height: 14px;
    font-size: 10px;
    font-weight: 500;
    color: #90949e;
    line-height: 14px;
  }

  .icon_add {
    position: absolute;
    right: 12px;
    top: 11px;
    width: 22px;
    height: 22px;
    border: 5px solid transparent;
    background-clip: padding-box;
  }

  .icon_has_added {
    position: absolute;
    right: 66px;
    top: 11px;
    width: 22px;
    height: 22px;
    border: 5px solid transparent;
    background-clip: padding-box;
  }

  .number {
    position: absolute;
    right: 39px;
    top: 17px;
    width: 32px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
}
</style>